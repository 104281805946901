export const seo = {
  url: 'logistics',
  title: {
    en: 'Logistics | Definition | Services | Warehousing | Insurance | News',
    es: 'Logística | Definición | Servicios | Almacenamiento | Seguro | Noticias',
    ro: 'Logistica | Definiție | Servicii | Depozitare | Asigurare | Știri',
  },
  desc: {
    en: 'Professional logistic services, warehousing, and insurance dedicated to specific industries. Find out how adapted logistics affects business optimization.',
    es: 'Servicios profesionales de logística, almacenaje y seguros dedicados a industrias específicas. Descubra cómo la logística adaptada afecta a la optimización del negocio.',
    ro: 'Servicii logistice profesionale, depozitare și asigurări dedicate unor industrii specifice. Aflați cum logistica adaptată afectează optimizarea afacerii.',
  },
  keywords: ['omida', 'logistics', 'warehousing', 'transport', 'insurance'],
}

export const intro = {
  title: {
    en: 'Logistics',
    es: 'Logística',
    ro: 'Logistică',
  },
  desc: {
    en: 'Professional logistic services, warehousing, and insurance dedicated to specific industries. Find out how adapted logistics affects business optimization.',
    es: 'Servicios profesionales de logística, almacenaje y seguros dedicados a industrias específicas. Descubra cómo la logística adaptada afecta a la optimización del negocio.',
    ro: 'Servicii logistice profesionale, depozitare și asigurări dedicate unor industrii specifice. Aflați cum logistica adaptată afectează optimizarea afacerii.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'What is logistics?',
        es: '¿Qué es la logística?',
        ro: 'Ce este logistica?',
      },
      texts: [
        {
          en: '<span>Logistics is the process of <strong>planning</strong>, <strong>realization</strong> and <strong>control</strong> of the stages of the raw material flow. It is the part of the supply chain that deals with planning, the process itself and the control of the smooth delivery of goods.</span>',
          es: '<span> La logística es el proceso de <strong> planificación </strong>, <strong> ejecución </strong> y <strong> control </strong> de las etapas del flujo de materia prima. Es la parte de la cadena de suministro que se ocupa de la planificación, el proceso en sí y el control de la entrega sin problemas de las mercancías.</span>',
          ro: '<span>Logistica este procesul de <strong>planificare</strong>, <strong>realizare</strong> și <strong>control</strong> a etapelor fluxului de materie primă. Este partea din lanțul de aprovizionare care se ocupă cu planificarea, procesul în sine și controlul livrării fără probleme a mărfurilor.</span>',
        },
      ],
    },
    {
      headline: {
        en: 'What logistics services does Omida Logistics offer?',
        es: '¿Qué servicios logísticos ofrece Omida Logistics?',
        ro: 'Ce servicii de logistică oferă Omida Logistics?',
      },

      texts: [
        {
          en: '<span>We offer comprehensive logistics services for <strong>e-commerce</strong> stores, <strong>contract logistics</strong>, as well as <strong>logistics center</strong>. Find out how our team can handle your <strong>e-Business</strong>.</span>',
          es: '<span> Ofrecemos servicios logísticos integrales para tiendas de <strong> comercio electrónico </strong>, <strong> logística de contrato </strong>, así como <strong> centro logístico </strong>. Descubra cómo nuestro equipo puede manejar su <strong> comercio electrónico </strong>. </span>',
          ro: '<span>Oferim servicii de logistică cuprinzătoare pentru magazine de <strong>e-commerce</strong>, <strong>logistica contractuală</strong>, precum și <strong>centru de logistică</strong>. Aflați cum se poate ocupa echipa noastră de <strong>e-Business</strong>.</span>',
        },
      ],
    },
  ],

  features: [
    {
      icon: 'boxes',
      subtitle: {
        en: 'Professional ',
        es: 'Profesional ',
        ro: 'Profesional ',
      },
      title: {
        en: 'Warehousing',
        es: 'Almacenamiento',
        ro: 'Depozitare',
      },
      desc: {
        en: 'Professional warehousing and value-added services, such as: preparation of sets, technical and quality controls and repackaging',
        es: 'Almacenamiento profesional y servicios de valor añadido como preparación de kits, control técnico y de calidad y reenvasado',
        ro: 'Servicii profesionale de depozitare și valoare adăugată, cum ar fi: pregătirea seturilor, controale tehnice și de calitate și reambalare',
      },
    },
    {
      icon: 'truck',
      subtitle: {
        en: 'Lower costs of ',
        es: 'Menores costos ',
        ro: 'Costuri mai mici ale',
      },
      title: {
        en: 'Logistics',
        es: 'Logísticos',
        ro: 'Logistică',
      },
      desc: {
        en: 'Low inventory levels and fewer late shipments thanks to appropriate management',
        es: 'Bajo inventario y menos envíos atrasados ​​gracias a una gestión adecuada',
        ro: 'Niveluri scăzute ale stocurilor și mai puține livrări cu întârziere datorită gestionării adecvate',
      },
    },
    {
      icon: 'document',
      subtitle: {
        en: 'Gestionarea retururilor',
        es: 'Manejo de devoluciones ',
        ro: '',
      },
      title: {
        en: 'Reporting',
        es: 'Informes',
        ro: 'Raportare',
      },
      desc: {
        en: 'Full and made to measure handling returns with a report on each event',
        es: 'Gestión completa y personalizada de las devoluciones con un informe de cada evento',
        ro: 'Gestionarea returnărilor complete și pe măsură cu un raport pentru fiecare eveniment',
      },
    },
    {
      icon: 'time',
      subtitle: {
        en: 'Real time ',
        es: 'Datos en tiempo ',
        ro: 'Timp real ',
      },
      title: {
        en: 'Data',
        es: 'Real',
        ro: 'Date',
      },
      desc: {
        en: 'Inventory control and the opportunity of making the right decisions already at the stage of ordering',
        es: 'Control de inventario y la capacidad de tomar las decisiones correctas en la etapa de pedido',
        ro: 'Controlul stocurilor și posibilitatea de a lua deciziile corecte deja în faza de comandă',
      },
    },
    {
      icon: 'dollar',
      subtitle: {
        en: 'Lower costs of ',
        es: 'Menores costos de ',
        ro: 'Costuri mai mici ale',
      },
      title: {
        en: 'Distribution',
        es: 'Distribución',
        ro: 'Distributie',
      },
      desc: {
        en: 'We concentrate your shipping volume, which will significantly reduce your delivery costs',
        es: 'Concentramos su volumen de envío, lo que le permitirá reducir significativamente los costos de envío',
        ro: 'Vă concentrăm volumul de expediere, ceea ce vă va reduce semnificativ costurile de livrare',
      },
    },
    {
      icon: 'unload',
      subtitle: {
        en: 'Integration with',
        es: 'Integración con empresas de',
        ro: 'Integrarea cu',
      },
      title: {
        en: 'Courier companies',
        es: 'Mensajería',
        ro: 'Firme de curierat',
      },
      desc: {
        en: 'We provide integration with courier companies, post and parcel machines. Competitive prices, thanks to the cooperation with a large operator',
        es: 'Brindamos integración con empresas de mensajería, máquinas postales y de paquetería. Precios competitivos gracias a la cooperación con un gran operador',
        ro: 'Oferim integrare cu companii de curierat, mașini de poștă și coletărie. Prețuri competitive, datorită cooperării cu un mare operator',
      },
    },
    {
      icon: 'settings',
      subtitle: {
        en: 'Customized',
        es: 'Oferta',
        ro: 'Personalizat',
      },
      title: {
        en: 'Personalization',
        es: 'Personalizada',
        ro: 'Personalizare',
      },
      desc: {
        en: 'We offer personalization on request (e.g. assembling sets, attaching inserts, packaging customization, and even laser engraving)',
        es: 'Ofrecemos personalización a pedido (incluidos juegos de plegado, insertos adjuntos, personalización de empaques e incluso grabado láser)',
        ro: 'Oferim personalizare la cerere (de exemplu, seturi de asamblare, inserții de atașare, personalizare ambalaj și chiar gravare cu laser)',
      },
    },
    {
      icon: 'server',
      subtitle: {
        en: 'System',
        es: 'Sistema',
        ro: 'Sistem',
      },
      title: {
        en: 'e-WMS',
        es: 'e-WMS',
        ro: 'e-WMS',
      },
      desc: {
        en: 'We are using computer software, which streamlines the inventory management system',
        es: 'Trabajamos en un software informático que mejora el sistema de gestión de almacenes',
        ro: 'Folosim software de calculator, care eficientizează sistemul de management al stocurilor',
      },
    },
  ],
}
